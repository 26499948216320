






















































































































































































































































































































































































































import Store from '@/models/Store.model';
import { namespace } from 'vuex-class';
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { StoresStoreActions } from '@/store/stores.store';
import { mixins } from 'vue-class-component';
import ErrorMessageHandlerMixin from '@/misc/ErrorMessageHandler.mixins';
import { validationMixin } from 'vuelidate';
import { required, email, decimal } from 'vuelidate/lib/validators';
import { decimalNumber, minValue, maxValue, url } from '@/misc/CustomValidators';
import BaseMixin from '@/misc/BaseMixin.mixins';
import EventBus from '@/misc/EventBus';
import AxiosErrorHandlerMixin from '@/misc/AxiosErrorHandler.mixin';

const StoresStore = namespace('stores');
const AuthStore = namespace('auth');

@Component({
  mixins: [validationMixin],
  validations: {
    strLat: { minValue: minValue(-90), maxValue: maxValue(90), decimalNumber },
    strLng: { minValue: minValue(-180), maxValue: maxValue(180), decimalNumber },
    storeCopy: {
      name: { required },
      webPage: { url },
      imprint: { url },
      address: {
        street: { required },
        houseNr: { required },
        zip: { required },
        city: { required },
        country: { required },
      },
      contact: {
        email: { required, email }
      },
      status: { required }
    }
  }
})
export default class EditStoreComponent extends mixins(ErrorMessageHandlerMixin, BaseMixin, AxiosErrorHandlerMixin) {
  @Prop({ default: () => new Store() })
  public store!: Store;

  // Is component loaded in modal dialog?
  @Prop({ default: true })
  public isModal!: boolean;

  @Prop({ default: false })
  public isRegisterPage!: boolean;

  @StoresStore.Action(StoresStoreActions.CREATE)
  private createStoreAction!: (store: Store) => Promise<Store>;

  @StoresStore.Action(StoresStoreActions.UPDATE)
  private updateStoreAction!: (store: Store) => Promise<Store>;

  @StoresStore.Action(StoresStoreActions.REGISTER_STORE)
  private registerStoreAction!: (store: Store) => Promise<Store>;

  private storeCopy: Store = new Store();
  private isValid = true;
  private isLoading = false;
  private selectedStep: number = 1;

  // Error message for duplicate name:
  private duplicateNameMessage: string | null = null;

  // Contain coordinates as strings - are converted to number in watcher
  private strLat?: string = '';
  private strLng?: string = '';

  private steps = [
    { title: 'STORE.DIALOG.MASTER_DATA', validations: ['storeCopy.name', 'storeCopy.webPage', 'storeCopy.imprint'], complete: false, valid: true },
    { title: 'STORE.DIALOG.ADDRESS', validations: [
      'storeCopy.address.street', 
      'storeCopy.address.houseNr', 
      'storeCopy.address.zip', 
      'storeCopy.address.city',
      'storeCopy.address.country',
      'strLat',
      'strLng'
      ], complete: false, valid: true },
    { title: 'STORE.DIALOG.CONTACT_PERSON', validations: ['storeCopy.contact.email'], complete: false, valid: true },
    { title: 'STORE.DIALOG.OPENING_HOURS', validations: [], complete: false, valid: true },
  ];

  private openingHours = [
    { title: 'GENERAL.WEEKDAYS.MONDAY', value: 'monday' },
    { title: 'GENERAL.WEEKDAYS.TUESDAY', value: 'tuesday' },
    { title: 'GENERAL.WEEKDAYS.WEDNESDAY', value: 'wednesday' },
    { title: 'GENERAL.WEEKDAYS.THURSDAY', value: 'thursday' },
    { title: 'GENERAL.WEEKDAYS.FRIDAY', value: 'friday' },
    { title: 'GENERAL.WEEKDAYS.SATURDAY', value: 'saturday' },
    { title: 'GENERAL.WEEKDAYS.SUNDAY', value: 'sunday' },
  ];

  mounted() {
    // Add event listener for saving data
    EventBus.$on('SAVE_STORE', this.saveData);
  }

  @Watch('store', { immediate: true })
  public onStoreChanged() {
    if (this.store) {
      this.storeCopy = this.store.copy() as Store;

      this.strLng = this.store.coordinates.lng?.toLocaleString(undefined, { useGrouping: false });
      this.strLat = this.store.coordinates.lat?.toLocaleString(undefined, { useGrouping: false });

      // Set openingHoursEntity if not set:
      if (!this.storeCopy.openingHoursEntity) {
        this.storeCopy.openingHoursEntity = Store.getDefaultOpeningHours();
      }
      // Trigger validation check when editing:
      if (this.store.id) {
        this.checkAllValidations();
      }
    }
    this.isValid = !this.$v!.$invalid;
  }

  /**
   * Prevents linear and non-linear change of step if validation failed
   */
  @Watch('selectedStep', { immediate: true })
  public onStepChange(newVal: number, oldVal: number) {
    this.$nextTick(async () => {
      if (oldVal) {
        const isValid = await this.checkValidations(this.steps[oldVal - 1]);
        this.steps[oldVal - 1].complete = isValid;
        // If previous step is lower (before) current step
        // (preventing to move forward if validation fails) 
        if (oldVal < newVal) {
          this.nextStep(oldVal, newVal);
        }
      }
    });
  }

  /**
   * Saves value from input field component as number in storeCopy.
   *  
   * Input field component saves value as string, but is needed as number. 
   * Using v-model.number or type="number" does not work with validator, because 
   * it displays invalid value, but doesn't change the value of the model.
   */
  @Watch('strLat', { immediate: true })
  private onStrLatChanged() {
    this.storeCopy.coordinates.lat = parseFloat(this.strLat!.replace(',', '.'));
  }

  @Watch('strLng', { immediate: true })
  private onStrLngChanged() {
    this.storeCopy.coordinates.lng = parseFloat(this.strLng!.replace(',', '.'));
  }

  /**
   * Moves from {currentStep} to {nextStep} in stepper if validation for {currentStep} succeeds.
   * @param currentStep Currently selected step.
   * @param nextStep Step to be switched to.
   */
  private async nextStep(currentStep: number = this.selectedStep, nextStep: number = this.selectedStep + 1) {
    if (!this.duplicateNameMessage) {
      const step = this.steps[currentStep - 1];
      const isValid = await this.checkValidations(step);
      if (isValid) {
        this.selectedStep = nextStep;
      } else {
        this.selectedStep = currentStep;
      }
    } else {
      this.selectedStep = currentStep;
    }
  }

  private duplicateNameError() {
    this.selectedStep = 1; // Move to step that contains name field
    this.duplicateNameMessage = this.$t('GENERAL.VALIDATION_MESSAGES.DUPLICATE_NAME').toString();
    this.steps[0].valid = false;
    this.isValid = false;
  }

  private resetDuplicateNameError() {
    this.duplicateNameMessage = null;
    this.steps[0].valid = true;
    this.isValid = true;
  }

  private addOpeningHoursSlot(day: { title: string, value: string }) {
    // @ts-ignore
    const entity = this.storeCopy.openingHoursEntity[day.value];
    entity.hours.push(['', '']);
  }

  private removeOpeningHoursSlot(day: { title: string, value: string }, index: number) {
    // @ts-ignore
    const entity = this.storeCopy.openingHoursEntity[day.value];
    entity.hours.splice(index, 1);
  }

  /**
   * Checks all validations for fields in given step.
   * @returns True if all validations are valid, else false.
   */
  private async checkValidations(step: any): Promise<boolean> {
    let isValid = true;
    for (let s of step.validations) {
      let valid = await this.checkForm(s);
      if (!valid) isValid = false;
    }
    step.valid = isValid;
    return isValid;
  }

  /**
   * Checks all validations of all steps.
   * Sets value for { this.isValid }.
   */
  private async checkAllValidations() {
    this.isValid = true;
    for (let step of this.steps) {
      if (!await this.checkValidations(step) || this.duplicateNameMessage) {
        this.isValid = false;
      }
    }
  }

  public async saveData() {
    await this.checkAllValidations();
    
    if (this.isValid && !this.isLoading) {
      try {
        this.isLoading = true;
        if (this.isRegisterPage) {
          const store = await this.registerStoreAction(this.storeCopy);
          EventBus.$emit('STORE_CREATED', store);
          this.storeCopy = store;
        } else {
          if (!this.store.id) {
            await this.createStoreAction(this.storeCopy);
          } else {
            await this.updateStoreAction(this.storeCopy);
          }
        }
        this.dismiss(true);
      } catch (e) {
        this.handleAxiosError(e, () => {
          switch (e.status) {
            case 409: // Duplicate name
              this.duplicateNameError();
              EventBus.$emit('DUPLICATE_STORE_NAME');
              break;
            default:
              this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.GENERAL_ERROR');
          }
        });
      } finally {
        this.isLoading = false;
      }
    }
  }

  private dismiss(reload: boolean = false) {
    this.$v.$reset();
    this.$emit('closeDialog', reload);
  }

  private async checkForm(type: string): Promise<boolean> {
    const inputValid = await this.triggerValidation(type);
    this.isValid = !this.$v!.$invalid;
    const affectedStep = this.steps.find(step => step.validations.indexOf(type) != -1);
    affectedStep!.valid = inputValid;
    return inputValid;
  }

  private storeRegistrationFinished() {
    this.checkAllValidations();
    if (this.isValid) {
      EventBus.$emit('STORE_REGISTRATION_FINISHED');
    }
  }
}
